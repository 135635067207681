body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  /*=====================
   Key Frames
  ======================*/
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes rotate {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  
/*=====================
   Key Frames
  ======================*/
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes rotate {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      border-top-color: #03A4E4;
      transform: rotate(90deg);
    }
    33.33% {
      border-top-color: #03A4E4;
      transform: rotate(180deg);
    }
    66.67% {
      border-top-color: #03A4E4;
      transform: rotate(270deg);
    }
    100% {
      border-top-color: #03A4E4;
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spinner {
    0% {
      border-top-color: #03A4E4;
      -webkit-transform: rotate(90deg);
    }
    33.33% {
      border-top-color: #03A4E4;
      -webkit-transform: rotate(180deg);
    }
    66.67% {
      border-top-color: #03A4E4;
      -webkit-transform: rotate(270deg);
    }
    100% {
      border-top-color: #03A4E4;
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spinner {
    0% {
      border-top-color: #03A4E4;
      transform: rotate(90deg);
    }
    33.33% {
      border-top-color: #03A4E4;
      transform: rotate(180deg);
    }
    66.67% {
      border-top-color: #03A4E4;
      transform: rotate(270deg);
    }
    100% {
      border-top-color: #03A4E4;
      transform: rotate(360deg);
    }
  }